.App {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  .App-header {
    >div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15%;
    }

    &::after {
      content: '';
      position: absolute;
      height: 1px;
      left: 0;
      bottom: 0;
      width: 100%;
      background: #ececec;
    }

    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    height: 44px;
    width: 100%;
    backdrop-filter: saturate(180%) blur(20px);
    background-color: rgba(255, 255, 255, 0.72);

    .yipei-logo {
      height: 25px;

      img {
        width: 25px;
      }
    }

    .header-con {
      flex: 1;
      display: flex;
      padding: 0 16px;
      align-items: center;
      font-size: 14px;
      color: #FF96AD;

      .menu-con {
        flex: 1;
      }

      .menu-footer {
        position: relative;

        .download-btns {
          position: absolute;
          top: 40px;
          left: -25%;
          color: #FF96AD;
          border-radius: 15px;
          backdrop-filter: saturate(180%) blur(20px);
          background-color: rgba(255, 255, 255, 0.82);
          border: #ececec 1px solid;
          padding: 8px 16px;

          .download-btns-wrapper {
            display: flex;
            flex-direction: column;
            div {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 40px;
              position: relative;

              img {
                width: 16px;
                height: 44px;
                margin-right: 8px;
              }


            }

            div:first-child {
              img {
                height: 20px;
                width: 20px;
              }

              &::after {
                content: '';
                position: absolute;
                height: 1px;
                left: 0;
                bottom: 0;
                width: 100%;
                background: #ececec;
              }
            }
          }


        }
      }

      a {

        &:link {
          color: #FF96AD;
          text-decoration: none;
        }

        &:visited {
          color: #FF96AD;
          text-decoration: none;
        }

        span {
          // background: #FF96AD;
          padding: 6px 10px;
          border-radius: 16px;
        }
      }
    }

  }
}

#main {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .App-footer {
    height: 44px;
  }
}