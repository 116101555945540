.yp-home {
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;

    .home-download {
        position: relative;
        flex: 0.4;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: url(../../res/bg1.jpg);
            // background-image: url(https://images.unsplash.com/photo-1582769923195-c6e60dc1d8dc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1600&q=80);
            // background-image: url(https://images.unsplash.com/photo-1496256654245-8f3d0ef3bebe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1268&q=80);
            // background-image: url(https://images.unsplash.com/photo-1422205512921-12dac7b3b603?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=4789&q=80);
            background-size: cover;
            // backdrop-filter: saturate(180%) blur(20px);
            // filter: blur(20px);
            // opacity: 0.9;
        }

        .info {
            position: absolute;
            left: 32px;
            bottom: 32px;
            color: white;
            display: flex;
            font-size: 13px;
            align-items: center;
            justify-content: start;
            flex-wrap: wrap;

            img {
                height: 20px;
            }

            a {
                padding: 0 8px;
                text-decoration: none;
                color: white;
                white-space: normal;
            }
        }

        .home-download-content {

            display: flex;
            flex-direction: column;
            justify-content: start;
            text-align: center;
            align-items: center;

            .yipei-logo {
                padding-bottom: 32px;
                display: flex;
                text-align: center;
                justify-content: center;

                img {
                    width: 50%;
                    height: fit-content;
                    box-shadow: 0 0 20px #FF96AD;
                    border-radius: 22px;
                }
            }

            .yipei-text {
                font-size: 64px;
                color: white;
                padding-bottom: 30px;
            }

            .desc {
                color: #FF96AD;
                color: white;

                font-size: 24px;

                padding-bottom: 16px;

                p {
                    font-size: 18px;
                }
            }

            .download-btns {
                display: flex;
                margin: 0 30px;

                div {
                    margin: 0 16px;
                    padding: 0 16px;
                    background: #FF96AD;
                    background: rgba($color: #ffffff, $alpha: 0.25);
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    color: white;
                    height: 40px;

                    img {
                        width: 16px;
                        height: 44px;
                        margin-right: 8px;
                    }
                }

                div:first-child {
                    img {
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }

    }

    .home-content {
        flex: 0.6;
        overflow: auto;
        // display: inline-flex;
        align-items: center;
        overflow: auto;
        position: relative;

        .img {
            // display: inline-block;
            // height: 100%;
            // width: 100%;
            overflow: hidden;

            img {
                overflow: hidden;
                // height: 100%;
                // width: fit-content;
                transform: scale(1.33);
                width: 100%;
                // height: 100%;

                // text-align: center;
            }
        }

        .info {
            color: gray;
            text-align: center;
            padding: 32px 16px;
            display: none;
            font-size: 13px;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            img {
                height: 20px;
                width: fit-content;
            }

            a {
                color: gray;
                padding: 0 8px;
                text-decoration: none;
                white-space: normal;
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .home-download {
        display: none !important;
    }

    .home-content {
        flex: 1 !important;

        .info {
            display: flex !important;
        }
    }
    .video_content .videos {
        height: 106vw !important;
    }
    .swiper {
        height: 96vw !important;
    }
}
.ewm {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 85px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    .ewmcontent {
        width: 110px;
        height: 130px;
        margin-left: 30px;
        margin-right: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .ewmimg {
            width: 110px;
            height: 110px;
        }
        .ewmtext {
            width: 100%;
            margin-top: 4px;
            text-align: center;
            font-size: 14px;
            color: #fff;
        }
    }
}
.video_content {
    width: 100%;
    height:auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 140px;
    
}
.video_content .videos {
    width: 57%;
    height: 60vw;
    position: relative;
    
}
.videos .videobgs {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1;
}
.swipers {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
    box-sizing: border-box;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    .swiper {
        width: 88%;
        height: 54vw;
        overflow: hidden;
        .swiperslide {
            width: 100%;
            height: 100%;
        }
    }
    
}
